import moment from "moment";
import { TodoModel } from "../../models";
import { CgRemove } from 'react-icons/cg';
import styles from './index.module.sass'

interface TodoListProperties {
    todos: TodoModel[];
    delete: (id: number) => void;
}

const TodoList = (props: TodoListProperties) => {
    return(
        <div className={styles.container}>
            {props.todos.map((todo) => {
                return(
                    <div key={todo.id} className={styles.todo}>
                        <div className={styles.content}>
                            <p>
                                {todo.text}
                            </p>
                        </div>
                        <div className={styles.time}>
                            {todo.createdAt && moment(todo.createdAt).fromNow()}
                        </div>
                        <div className={styles.actions}>
                            <div className={styles.actionItem} onClick={(e) => {e.preventDefault(); props.delete(Number(todo.id))}}>
                                <CgRemove />
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    );
}

export default TodoList;