import React, { useState } from "react";
import { TodoModel } from "../../models";
import styles from './index.module.sass';

interface TodoEntryProperties {
    add: (model: TodoModel) => void;
    user: number;
}

const TodoEntry = (props: TodoEntryProperties) => {
    const [text, setText] = useState("");

    const Handle = (e: React.MouseEvent) => {
        e.preventDefault();

        const model: TodoModel = {
            text,
            id: undefined,
            createdAt: undefined,
            user: props.user
        }

        props.add(model);
        setText("");
    };

    return(
        <form className={styles.container}>
            <input type="text" placeholder="Do the dishes, wash the car... I regret this." value={text} onChange={(e) => setText(e.target.value)} />
            <button onClick={Handle}>Add</button>
        </form>
    )
}

export default TodoEntry;