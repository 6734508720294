import { useState } from 'react';
import axios from 'axios';
import { UserModel } from '../../models';
import styles from './index.module.sass';

interface AuthProperties {
    setUser: (model: UserModel) => void;
    api: string;
}

/**
 * Login Component.
 * @param props Login Component Properties.
 * @returns Login Component JSX Element.
 */
const Auth = (props: AuthProperties) => {
    const [isRegistering, setIsRegistering] = useState(false);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const Next = (e: React.MouseEvent) => {
        e.preventDefault()

        if((isRegistering && name === "") || email === "" || password === "")
            return;

        const model: UserModel = {
            name,
            email,
            password,
            id: undefined
        };

        if(isRegistering){
            Register(model);
        } else{
            Login(model);
        }
    }

    const Login = (model: UserModel) => {
        axios.post(`${props.api}/user/login`, model)
        .then((response) => {
            if(response.data.error){
                //TODO: Handle
                return;
            }
            else{
                const userData: UserModel = {
                    name: response.data.name,
                    email: response.data.email,
                    password: undefined,
                    id: response.data.id
                }

                props.setUser(userData);
            }
        });
    }

    const Register = (model: UserModel) => {
        axios.post(`${props.api}/user/register`, model)
        .then((response) => {
            if(response.data.error){
                return;
            }
            else{
                const userData: UserModel = {
                    name: response.data.name,
                    email: response.data.email,
                    password: undefined,
                    id: response.data.id
                };

                props.setUser(userData);
            }
        })
    }

    return(
        <div className={styles.container}>
            <div className={styles.header}>
                <p>Hi👋</p>
            </div>
            <form className={styles.selectionForm}>
                <button onClick={(e) => {e.preventDefault(); setIsRegistering(!isRegistering)}}>{isRegistering ? 'Switch to Login' : 'Switch to Register'}</button>
            </form>
            <form className={styles.entryForm}>
                {isRegistering && <input type="name" placeholder="Full Name" value={name} onChange={(e) => {setName(e.target.value)}} />}
                <input type="email" placeholder="Email Address" value={email} onChange={(e) => {setEmail(e.target.value)}} />
                <input placeholder="Password" type="password" value={password} onChange={(e) => {setPassword(e.target.value)}} />
                <button onClick={(e) => Next(e)}>{isRegistering ? 'Register Account' : 'Login'}</button>
            </form>
        </div>
    );
};

export default Auth;