import { useState } from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';

import { UserModel } from '../../models';
import { Auth, Home } from '..';
import styles from './index.module.sass';

interface ApplicationProperties {
    devMode: boolean;
}

/**
 * The Application Component.
 * @param props Application Component Properties.
 * @returns Application Component JSX Element.
 */
const Application = (props: ApplicationProperties) => {
    const [user, setUser] = useState<UserModel | undefined>(undefined)

    return(
        <Router>
            <div className={styles.container}>
                <div className={styles.content}>
                    {user === undefined && (
                        <Auth setUser={setUser} api={props.devMode ? 'http://localhost:3001' : '/api'} />
                    )}
                    {user !== undefined && (
                        <Switch>
                            <Route exact path="/">
                                <Home user={user} api={props.devMode ? 'http://localhost:3001' : '/api'} />
                            </Route>
                        </Switch>
                    )}
                </div>
            </div>
        </Router>
    );
};

export default Application;