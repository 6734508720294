import axios from 'axios';
import { useEffect, useState } from 'react';
import { TodoList } from '../';
import { UserModel, TodoModel } from '../../models';
import TodoEntry from '../todoentry';
import styles from './index.module.sass';

interface HomeProperties {
    user: UserModel;
    api: string;
};

const Home = (props: HomeProperties) => {
    const [todoItems, setTodoItems] = useState<TodoModel[] | undefined>(undefined)
    const [updater, setUpdater] = useState(false)

    useEffect(() => {
        axios.get(`${props.api}/todo/${props.user.id}`)
        .then((response) => {
            if(response.data.error) {
                // TODO: Handle.
            }
            else {
                setTodoItems(response.data.todo);
            }
        });
    }, [props.user, updater, props.api])

    const Add = (model: TodoModel) => {
        axios.post(`${props.api}/todo/`, model)
        .then((response) => {
            if(response.data.error) {
                // TODO: Handle.
            }
            else {
                setUpdater(!updater)
            }
        });
    };

    const Delete = (id: number) => {
        axios.delete(`${props.api}/todo/${id}`)
        .then((response) => {
            if(response.data.error) {
                // TODO: Handle.
            }
            else {
                setUpdater(!updater)
            }
        });
    };

    return(
        <div className={styles.container}>
            <div className={styles.header}>
                <div className={styles.titles}>
                    <p className={styles.heading}>
                        Hi, {props.user.name?.split(" ")[0]}👋
                    </p>
                    <p className={styles.subheading}>
                        What to do...
                    </p>
                </div>
            </div>
            <div className={styles.content}>
                <div className={styles.todoContainer}>
                    <div className={styles.todoList}>
                        {todoItems !== undefined && (
                            <TodoList todos={todoItems} delete={Delete} />
                        )}
                        {(todoItems === undefined || todoItems.length === 0) && (
                            <p className={styles.noitems}>Looks like you've got nothing to do, huh? Add some stuff below!</p>
                        )}
                    </div>
                    <div className={styles.todoEntry}>
                        <TodoEntry add={Add} user={Number(props.user.id)} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;